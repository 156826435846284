
// Holds helper mixins (extends compass functionality)

// ***********************************************
// Markup free clearing
// ***********************************************

@mixin clearfix-after {
  &::after{
    clear:both;
    content:" ";
    display:table;
    visibility:hidden;
  }
}

// ***********************************************
// Menus
// ***********************************************
@mixin horizontal-menu($withcutlines: null){
  width:auto;
  @include clearfix-after;
  > li{
    display:block;
    float:left;
    @if($withcutlines != null){
      border-width:0 1px 0 0;
      border-style:solid;
      border-color:$grey-light;
      &.last{ border-right:0; }
    }
  }
}

@mixin vertical-menu($withcutlines: null){
  // Only use case for this, is overriding a horizontal menu
  // A menu without styling allready is a vertical menu!
  > li{
    display:block;
    @if($withcutlines != null){
      border-width:0 0 1px;
      &.last{ border-bottom:0; }
    }
  }
}

// ***********************************************
// Simple Linear Gradients
// ***********************************************
@mixin vertical-gradient($first-color: $black, $second-color: $white) {
  background-image: linear-gradient(to top, $first-color 0%, $second-color 100%);
}
@mixin horizontal-gradient($first-color: $black, $second-color: $white) {
  background-image: linear-gradient(to right, $first-color 0%, $second-color 100%);
}

//
// element-invisible improved and more robust
//
@mixin element-invisible {
  border: 0;
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  @if $legacy-support-for-ie6 or $legacy-support-for-ie7 {
    clip: rect(1px 1px 1px 1px); // IE6 and IE7 use the wrong syntax
  }
  clip: rect(1px, 1px, 1px, 1px);
}

//
// Turns off the element-invisible effect
//
@mixin element-invisible-off {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: auto;
}

// ***********************************************
// Animation & Keyframe Mixin
// ***********************************************

// Animate.css Library

@mixin animated($duration:1s) {
  animation-duration: $duration;
  animation-fill-mode: both;
}
@mixin animated-infinite{
  animation-iteration-count: infinite;
}
@mixin animated-hinge {
  animation-duration: 2s;
}
@mixin animated-flip-bounce {
  animation-duration: .75s;
}

// Animation Mixins
// Bourbon missing animation-property mixin, so ...
@mixin animation-property($property, $value) {
  -webkit-animation-#{$property}: unquote($value);
  -moz-animation-#{$property}: unquote($value);
  -o-animation-#{$property}: unquote($value);
  animation-#{$property}: unquote($value);
}

// ***********************************************
// Forms
// ***********************************************

// Inline Form Groups
@mixin inline-form($field-selectors: ".form-text", $button-selector: ".form-submit"){
  display: table; // flex fallback
  display: flex;
  > *{
    display:table-cell; // flex fallback
  }
  .form-actions{
    padding:0;
    margin:0;
    .form-submit{
      margin:0;
      // this is not working very well.. padding:7px 5px; /* Button Reset (Same height as the textfield) */
    }
  }
  #{$field-selectors}{
    // Set width here?
  }
  #{$button-selector}{
    // Set width here?
  }
}
