
// ***********************************************
// Buttons
// ***********************************************

@mixin btn($color-scheme: 'default', $size: 'default', $icon: null, $icon-only: 'false'){
  border-width:1px;
  border-style:solid;
  display: inline-block;
  font-weight: $font-weight-normal;
  cursor:pointer;
  text-align: left;
  text-decoration: none;
  vertical-align: top;
  user-select: none;

  // Color
  @include btn-mod($color-scheme);

  // Size
  @include btn-size($size);

  // Icon Only (remove text, show only the icon)
  @if($icon-only != 'false'){
    text-indent:-9000px;
    position:relative;
    padding:0;
    min-width:$icon-size-normal + ($btn-default-h-space * 2);
    line-height:$icon-size-normal + ($btn-default-v-space * 2);
  }@else{
    line-height: 1.05em;
  }

  @if($icon-only != 'false' OR $icon != null){
    &::before{
      // Icon
      @if($icon-only != 'false'){
        text-indent:0;
        float:left;
        vertical-align:middle;
        width:100%;
        text-align:center;
      }@else{
        line-height: 1;
      }
      @if($icon != null){
        display: inline-block;
        font-family: $iconfont;
        font-style: normal;
        font-weight: normal;
        margin-right:5px;
        text-align:center;
        content: map-get($icon-list, $icon);
      }
    }
  }
  &:hover{
    text-decoration:none;
  }
}

// Button Modifier
// -- Size
@mixin btn-size($size){
  @if($size == xs){
    padding:$btn-xs-v-space $btn-xs-h-space;
    border-radius:$btn-radius;
    line-height:$btn-xs-line-height;
    font-size:$btn-xs-font-size;
  }@else if( $size == xl){
    padding:$btn-xl-v-space $btn-xl-h-space;
    border-radius:$btn-radius;
    line-height:$btn-default-line-height;
    font-size:$btn-default-font-size;
  }@else if( $size == xxl){
    padding:$btn-xxl-v-space $btn-xxl-h-space;
    border-radius:$btn-radius;
    line-height:$btn-default-line-height;
    font-size:$btn-default-font-size;
  }@else if( $size == xxxl){
    padding:$btn-xxxl-v-space $btn-xxxl-h-space;
    border-radius:$btn-radius;
    line-height:$btn-default-line-height;
    font-size:$btn-default-font-size;
  }@else{
    // Default
    padding:$btn-default-v-space $btn-default-h-space;
    border-radius:$btn-radius;
    line-height:$btn-default-line-height;
    font-size:$btn-default-font-size;
  }
}
// -- Style
@mixin btn-mod($color-scheme, $actions-only: 'false', $hard-override: 'false'){
  // Adds only the new colors, not the entire button code
  // $actions-only: For buttons they show onother color scheme on :hover & :active
  $imp: if($hard-override == 'true', '!important', null);

  @if($color-scheme == 'red'){
    @if($actions-only == 'false'){
      background-color:$danger $imp;
      border-color:$danger $imp;
      text-shadow:0 1px 1px lighten($danger, 12%);
      color: $white $imp;
    }
    &:hover{
      background-color: lighten($danger, 3%) $imp;
      border-color: lighten($danger, 3%) $imp;
      color: $white !important;
    }
    &:active,
    &:focus{
      background-color:darken($danger, 3%) $imp;
      border-color:darken($danger, 3%) $imp;
      color:darken($danger, 20%) $imp;
    }
  }@else if( $color-scheme == 'green'){
    @if($actions-only == 'false'){
      background-color:$sucess $imp;
      border-color:$sucess $imp;
      color: $white $imp;
    }
    &:hover{
      background-color: lighten($sucess, 3%) $imp;
      border-color: lighten($sucess, 3%) $imp;
      color: $white $imp;
    }
    &:active,
    &:focus{
      background-color: darken($sucess, 3%) $imp;
      border-color: darken($sucess, 3%) $imp;
      color:darken($sucess, 20%) $imp;
    }
  }@else if( $color-scheme == 'grey'){
    @if($actions-only == 'false'){
      background-color: darken($grey-light, 3%) $imp;
      border-color: darken($grey-light, 3%) $imp;
      color:$text-color $imp;
    }
    &:hover{
      background-color: $highlight $imp;
      border-color: $highlight $imp;
      color:$highlight-text-color $imp;
    }
    &:active,
    &:focus{
      background-color: darken($grey-light, 3%) $imp;
      border-color: darken($grey-light, 3%) $imp;
      color:darken($grey, 20%) $imp;
    }
  }@else if( $color-scheme == 'disabled'){
    @if($actions-only == 'false'){
      background-color: darken($grey-light, 3%) $imp;
      border-color: $grey $imp;
      color:darken($grey, 15%) $imp;
    }
    &:hover{
      background-color: darken($grey-light, 5%) $imp;
      border-color: $grey $imp;
      color:darken($grey, 15%) $imp;
    }
    &:active,
    &:focus{
      background-color:darken($grey-light, 3%) $imp;
      border-color:darken($grey-light, 3%) $imp;
      color:darken($grey-light, 20%) $imp;
    }
  }@else if( $color-scheme == 'ghost'){
    @if($actions-only == 'false'){
      background-color:$btn-ghost-bg-color $imp;
      border-color:$btn-ghost-border-color $imp;
      color:$btn-ghost-text-color $imp;
    }
    &:hover{
      background-color: $btn-ghost-bg-color $imp;
      border-color:lighten($btn-ghost-border-color, 8%) $imp;
      color:$highlight $imp;
    }
    &:active,
    &:focus{
      background-color:$btn-ghost-bg-color $imp;
      border-color:darken($btn-ghost-border-color, 5%) $imp;
      color:$btn-ghost-text-color $imp;
    }
  }@else if( $color-scheme == 'ghost-dark'){
    @if($actions-only == 'false'){
      background-color:$btn-ghost-dark-bg-color $imp;
      border-color:$btn-ghost-dark-border-color $imp;
      color:$btn-ghost-dark-text-color $imp;
    }
    &:hover{
      background-color: $btn-ghost-dark-bg-color $imp;
      border-color:lighten($btn-ghost-dark-border-color, 5%) $imp;
      color:$btn-ghost-dark-text-color $imp;
    }
    &:active,
    &:focus{
      background-color:$btn-ghost-dark-bg-color $imp;
      border-color:darken($btn-ghost-dark-border-color, 5%) $imp;
      color:$btn-ghost-dark-text-color $imp;
    }
  }@else if( $color-scheme == 'highlight'){
    @if($actions-only == 'false'){
      // Active (default button active state)
      border-color:$highlight $imp;
      background-color:$highlight $imp;
      color: $highlight-text-color $imp;
    }
    &:hover{
      border-color:lighten($highlight, 5%) $imp;
      background-color:lighten($highlight, 5%) $imp;
      color: $highlight-text-color $imp;
    }
    &:active,
    &:focus{
      border-color:darken($highlight, 5%) $imp;
      background-color:darken($highlight, 5%) $imp;
      color: $highlight-text-color $imp;
    }
  }@else if( $color-scheme == 'naked'){
    @if($actions-only == 'false'){
      // Active (default button active state)
      border-color:transparent $imp;
      background-color:transparent $imp;
      color: $text-color $imp;
      text-decoration:underline;
      padding-left:0 $imp;
      padding-right:0 $imp;
    }
    &:hover{
      border-color:transparent $imp;
      background-color:transparent $imp;
      color: $highlight $imp;
    }
    &:active,
    &:focus{
      border-color:transparent $imp;
      background-color:transparent $imp;
      color: $highlight $imp;
    }
  }@else if( $color-scheme == 'active'){
    @if($actions-only == 'false'){
      // Active (default button active state)
      border-color:darken($grey-light, 5%) $imp;
      background-color:darken($grey-light, 8%) $imp;
      color: $text-color $imp;
    }
    &:hover{
      background-color: $grey-light $imp;
      border-color: $grey-light $imp;
      color: $grey-dark $imp;
    }
    &:active,
    &:focus{
      border-color:darken($grey-light, 5%) $imp;
      background-color:darken($grey-light, 8%) $imp;
      color: $text-color $imp;
    }
  }@else{
    @if($actions-only == 'false'){
      border-color:$btn-default-border-color $imp;
      background-color:$btn-default-bg-color $imp;
      color: $btn-default-text-color $imp;
    }
    &:hover{
      border-color:lighten($btn-default-border-color, 5%) $imp;
      background-color:lighten($btn-default-bg-color, 5%) $imp;
      color: $btn-default-text-color $imp;
    }
    &:active,
    &:focus{
      border-color:darken($btn-default-border-color, 5%) $imp;
      background-color:darken($btn-default-bg-color, 5%) $imp;
      color: $btn-default-text-color $imp;
    }
  }
}

// Image Buttons (completely seperate button style)
@mixin btn-image {
  text-indent:-9000px;
  display:inline-block;
  background-repeat:no-repeat;
  background-image:url("images/btn_sprite.png");
  background-color:transparent;
  border:0 none;
  outline:0 none;
  height:40px;
  width:154px; // fallback
}

// Undo Buttons
@mixin undo-button {
  font-size:$font-size;
  color:$text-color;
  background:none;
  border:0 none;
  padding:0;
  display:inline;
  &:hover{
    text-decoration:underline;
  }
}
